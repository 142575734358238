import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCUkcZTPOrOHz-l_odAGw4HI7HyxZW0Khs',
  authDomain: 'zetacapital-test.firebaseapp.com',
  projectId: 'zetacapital-test',
  storageBucket: 'zetacapital-test.appspot.com',
  messagingSenderId: '136109942645',
  appId: '1:136109942645:web:39765753b61855535d153c',
  measurementId: 'G-7D3XLB98CZ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export { db, app }
