export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'portfolios',
      displayName: 'menu.portfolios',
      meta: {
        icon: 'contact_support',
      },
    },
    {
      name: 'testimonials',
      displayName: 'menu.testimonials',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'pricings',
      displayName: 'menu.pricings',
      meta: {
        icon: 'attach_money',
      },
    },
  ] as INavigationRoute[],
}
