<template>
  <!-- Login Logo -->
  <h1 v-if="props.start == '#FFF'" style="color: #fff; font-weight: bolder; font-size: 18px">
    <span style="color: rgb(255 0 0)">Z</span>
    <span style="color: rgb(230 3 2)">E</span>
    <span style="color: rgb(210 6 0)">T</span>
    <span style="color: rgb(190 9 0)">A</span>
    Capital
  </h1>
  <!-- Home Logo -->
  <h1 v-else style="font-weight: bolder; font-size: 18px">
    <span style="color: rgb(255 0 0)">Z</span>
    <span style="color: rgb(230 3 2)">E</span>
    <span style="color: rgb(210 6 0)">T</span>
    <span style="color: rgb(190 9 0)">A</span>
    Capital
    <span style="font-weight: normal; font-style: italic; margin-left: 5px; font-size: 12px; color: #aaa">admin</span>
  </h1>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
